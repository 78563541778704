body {
  background-color: #FFFFFF;
  /* background-color: #ccf0fd; */
  /* background-color: #cce0e7; */
  /* background-color: #0e6eca; */
  font-family: 'Montserrat', 'Arial', sans-serif !important;
  overflow-x: hidden;
}

.outercanvas {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
}

canvas {
  position: fixed;
  right: 50%;
  left: 50%;
  height: 100vh !important;
  width: auto !important;
  -ms-transform: translateX(-50%);/* translateY(-50%); */
  -moz-transform: translateX(-50%);/* translateY(-50%); */
  -webkit-transform: translateX(-50%);/* translateY(-50%); */
  transform: translateX(-50%);/* translateY(-50%); */
  background-size: cover;
  pointer-events: none;
  background-color: none !important;
  z-index: -1;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0%;
  left: 0%;
  right: 0;
  bottom: 0;
  background-color: rgba(36,82,194,0.8); /* Black background with opacity */
  z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  overflow: hidden;
}

#upload_count_success {
  display: none;
}

#count_connected {
  font-size: 25px;
}

#count_total {
  font-size: 25px;
}

#keypad {
  display: flex;
  justify-content: center;
}

.text--line {
  display: flex;
  align-items: center;
  font-size: 15em;
}

svg {
  /* display: inline-block; */
  /* width: 100%; */
  /* min-width: 300px; */
  width: 100%;
  height: 100%;
  max-height: 100px !important;
  margin-bottom: 0;
}

.contribute {
  color: #2452c2;
}

.instagram {
  padding-top: 3em;
}

#contribute {
  /* background-color: #ccf0fd; */
  /* background-image: url('../assets/contribute_bg.png') !important;
  /* background-position: top;
  background-repeat: no-repeat; */
  /* opacity: 0.5; */
  /* background-color: #ccc 0.6; */
  /* background-color: rgba(244, 244, 244, 0.8); */
  border-top-color: #2452c2;
  border-top-width: 1px;
  border-top-style: solid;
  background-image: linear-gradient(rgba(244, 244, 244, 0.8) , rgba(244, 244, 244, 0.97));
  z-index: 2;
}


.text-copy {
  fill: none;
  stroke: white;
  stroke-dasharray: 20% 28%;
  stroke-width: 9px;
  -webkit-animation: stroke-offset 10s infinite linear;
          animation: stroke-offset 10s infinite linear;
}
.text-copy:nth-child(1) {
  stroke: #2452c2;
  stroke-dashoffset: 0%;
}
.text-copy:nth-child(2) {
  stroke: #CCDEFF;
  stroke-dashoffset: 40%;
}
.text-copy:nth-child(3) {
  stroke: #f2c200;
  stroke-dashoffset: 60%;
}
.text-copy:nth-child(4) {
  stroke: #b94db3;
  stroke-dashoffset: 80%;
}
.text-copy:nth-child(5) {
  stroke: #ce3b43;
  stroke-dashoffset: 100%;
}

.numeranimation {
  padding-bottom: 2em;
}

@-webkit-keyframes stroke-offset {
  from {
    stroke-dashoffset: 0%;
  }
  to {
    stroke-dashoffset: 100% 100% 100% 100% 100%;
  }
}

@keyframes stroke-offset {
  from {
    stroke-dashoffset: 0%;
  }
  to {
    stroke-dashoffset: 100% 100% 100% 100% 100%;
  }
}

.contribute-text {
  padding-top: 4em;
  padding-bottom: 2em;
}

.rounded-image {
  border-radius: 25px;
}

hr.new {
  border: 1px solid #2452c2;
  border-radius: 2px;
}

.patreonlink {
  background-color: #fff;
  margin: 0.1em !important;
  /* padding: 0.5rem 1rem !important;
  padding:0 0.7rem!important; */
  padding-top: 0!important;
  padding-bottom: 0!important;
  padding-left: 0.7rem!important;
  padding-right: 0.7rem!important;
  height: 42px;
  width: 42px;
}

.patreonlink img {
  /* margin: auto; */
  padding-top:  0.7rem !important;
  min-width: 20px !important;
  /* width: 28px !important; */
  line-height: 1.8;
}

.instagramlink {
  background-color: #fff;
  margin: 0.1em !important;
  /* padding: 0.5rem 1rem !important; */
  padding:0 0.7rem!important;
  height: 42px;
}

.instagramlink i {
  font-size: 25px;
  margin: auto;
  line-height: 1.8;
  width: auto;
}

@media only screen and (min-width: 760px) {

  #warningtext{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }

  .titleresize-found-one {
    text-align: center;
    font-weight: bold;
    font-size: 75px;
    line-height: 0.7;
  }

  .warning{
    color: #7e7e7e;
    margin-top: 2em;
    font-size: 15px;
  }

  .codeinputfield {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .codefield {
    color: #2452c2;
    /* border-radius: 10px; */
    border: #2452c2 1px solid !important;
  }

  .icon {
    background: #fff;
    color: #2452c2;
    margin: 3px;
    /* padding-left: 30px;
    padding-right: 30px; */
    line-height: 70px;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    width: 1.5em;
    height: 1.5em;
  }

  .icon:hover {
    /* outline: 4px #ff6666 solid;
    outline-offset: -4px; */
    /* background-color: #ff6666; */
    color: #ff6666;
  }

  .codeinput {
    /* background-color: #fff !important; */
  }

  #code {
    /* margin-left: auto;
    margin-right: auto; */
    /* padding: 9px; */
    /* padding-bottom: 2px; */
    padding: 6px;
    height: 45px;
    line-height: 25px;
    font-size: 25px;
    /* margin-bottom: 0.3em; */
    margin-right: 0.3em;
    font-family: "Ionicons", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    letter-spacing: 3px;
  }

  #uploadBar {
    display: none;
  }

  .homelink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .homelink:hover {
    background-color: #b94db3;
    color: #fff !important;
    /* line-height: 1.2; */
  }

  .contributelink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .contributelink:hover {
    background-color: #cc3d46;
    color: #fff !important;
    text-decoration: underline wavy #cc3d46;
  }

  .aboutlink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .aboutlink:hover {
    background-color: #2452c2;
    color: #fff !important;
    text-decoration: underline wavy #2452c2;
  }

  .about {
    background-image: linear-gradient(rgba(244, 244, 244, 0.97) , rgba(244, 244, 244, 0.8));
    color: #2452c2;
    z-index: 1;
  }
  .codeDeletionButton {
    margin-top: 1em !important;
  }

  .delete {
    display: flex;
    padding-top: 1em;
    justify-content: center;
  }

  .uploadbuttonmiddle {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .progress-meter {
    background-color: #000;
  }

  .progress {
    background-color: #dfdfdf;
  }

  .titleresize {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    /* text-transform: uppercase; */
    /* color: #2452c2; */
    line-height: 1.5;
    text-decoration: underline wavy #c0e0ff;
  }

  .titleresize-connect {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    /* text-transform: uppercase; */
    color: #2452c2;
    line-height: 1.1;
    /* color: black; */

  }

  .titleresize-about {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    /* text-transform: uppercase; */
    /* color: #2452c2; */
    line-height: 1.2;
    /* text-decoration: underline wavy #2452c2; */
    text-underline-offset: 5px;
  }

  .subtitleresize {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.4;
  }

  .subtitleresize-center {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.4;
  }

  .subtitleresize-center-code {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 0.0;
  }

  .subtitleresize-center-textfield {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.0;
  }

  .numberresize {
    font-size: 120px;
    font-weight: bold;
    line-height: 1.1;
    -webkit-text-fill-color: #1aaf7b; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: #ffffff;
    text-align: center;
  }

  .textresize {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
  }

  .textresize-center a {
    color: #2452c2 !important;
    font-weight: bold !important;
    text-decoration: underline !important;
  }

  .textresize-center {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
  }

  .credits {
    color: #2452c2;
    background-color: rgba(244, 244, 244, 0.8);
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
  }

  .row-info {
    padding: 2em;
    padding-top: 5em;
    padding-bottom: 10em !important;
  }

  .uploadbutton {
    padding: 1em 3em 1em 3em;
    background-color: #fff !important;
    color: #2452c2 !important;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    /* border-radius: 10px; */
    border: #2452c2 1px solid !important;
    /* border: 4px solid #ff6666; */
  }

  ::selection {
    background: #ffffff; /* WebKit/Blink Browsers */
  }
  ::-moz-selection {
    background: #ffffff; /* Gecko Browsers */
  }

  .uploadbutton:hover {
    padding: 1em 3em 1em 3em;
    background-color: #fff !important;
    color: #ff6666 !important;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    /* border: 4px solid #ff6666; */
  }

  .uploadbuttonSelected {
    padding: 1em 1em 1em 1em;
    background-color: #fff !important;
    opacity: 0.7;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    /* border: 4px solid #ff6666; */
  }

  #afterupload {
    display: none;
  }

  #MissingFile, #MissingCode, #InvalidFileParameter, #InvalidFileType, #InvalidFileSize, #InvalidCodeFormat, #GeolocationUnsupported, #GeolocationDenied, #GeolocationUnavailable, #GeolocationTimeout, #InvalidCode, #UploadSize, #UploadError {
    display: none;
    font-size: 20px;
    color: #ff6666;
    text-align: center;
  }

  .finaluploadbutton {
    margin-top: 1em !important;
    background-color: #ffffff !important;
    font-size: 30px !important;
    font-weight: bold !important;
    cursor: pointer;
    color: #2452c2 !important;
    /* border-radius: 10px; */
    border: #2452c2 1px solid !important;
  }

  .finaluploadbutton:hover {
    background-color: #2452c2 !important;
    font-size: 30px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    cursor: pointer;
  }

  .uploadbutton:hover {
    /* background-color: #f2c200 !important; */
    color: #ff6666;
  }

  .light {
    /* background-color: #dbf5ff; */
    background-color: #fff;
  }

  .buttonsCode {
    display: flex;
    justify-content: center;
  }

  .image-upload {
    text-align: center;
    min-height: 800px !important;
    border: none;
    font-size: 17px;
    /* padding: 2em; */
    /* outline-offset: -1em; */
    /* background-color: #FFF; */
    color: #000;
    /* outline: 2px dashed #2452c2; */
    align-items: center !important;
    justify-content: center;
    display: flex;
    background-image: url('../assets/photos/placeholder_photo_circle_red.png') !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .image-upload:hover {
    /* background-color: #e6ebf7; */
    /* outline: 2px dashed #757575 !important; */
  }

  .messageField {
    margin-top: 2em;
    border: #2452c2 1px solid !important;
    font-size: 17px;
    /* padding: 2em; */
    background-color: #FFF;
    /* outline: 2px solid #2452c2 !important; */
    min-height: 100px;
    /* font-size: 17px; */
    color: #2452c2 !important;
    /* outline-offset: 0.9em; */
    /* border-radius: 10px; */
  }

  .messageField:hover {
    background-color: #FFF;
    /* outline: 2px dashed #757575 !important; */
  }

  .messageField:focus {
    background-color: #FFF;
    /* outline: 2px dashed #757575 !important; */
    outline-offset: 0.9em;
  }

   textarea::-webkit-input-placeholder {
    color: #2452c2;
  }

  textarea:-moz-placeholder {
    color: #2452c2;
  }

  textarea::-moz-placeholder {
    color: #2452c2;
  }

  textarea:-ms-input-placeholder {
    color: #2452c2;
  }

  textarea::placeholder {
    color: #2452c2;
  }







  input::-webkit-input-placeholder {
   color: #2452c2;
   font-size: 20px;
   letter-spacing: normal;
 }

 input:-moz-placeholder {
   color: #2452c2;
   font-size: 20px;
   letter-spacing: normal;
 }

 input::-moz-placeholder {
   color: #2452c2;
   font-size: 20px;
   letter-spacing: normal;
 }

 input:-ms-input-placeholder {
   color: #2452c2;
   font-size: 20px;
   letter-spacing: normal;
 }

 input::placeholder {
   color: #2452c2;
   font-size: 20px;
   letter-spacing: normal;
 }



  .messageupload {
    margin-top: 5em;
  }

  .menutext:hover {
    /* color: #ff6666 !important; */
  }

  .logo {
    height: 30px !important;
    top: 1em;
    left: 1em;
    position: fixed;
    font-size: 20px;
    z-index: 3;
    max-width: 250px;
  }

  .logonew {
    top: 35px;
    position: fixed;
    z-index: 4;
  }

  .lbu {
    top: 35px !important;
    right: 2em !important;
    position: fixed !important;
    z-index: 5;

    padding: .25em !important ;
    padding-left: .25em !important;
    padding-right: .25em !important;
    margin: 0px !important;
    font-weight: bold;
    font-size: 20px;
  }

  .menutext {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .counter {
    position: absolute;
    bottom: 1.7em;
    right: 2em !important;
    font-weight: bold;
    /* display: flex; */
    text-align: right;
    line-height: 1.0;
    font-size: 20px;
    /* background-color: #ccf0fd; */
    opacity: 0.8;
    padding: 1em !important;
  }
}


.counter { transition: opacity 1s; opacity:1; transition-delay: 1s; }
canvas { transition: opacity 2s; opacity:1; transition-delay: 0.5s; }
.show, .show[style] { opacity:1 !important; }



*{box-sizing: border-box; -webkit-box-sizing: border-box; }
html, body { height: 100%; }
body { margin: 0; font: 16px/1.3 sans-serif; }

/*
PURE RESPONSIVE CSS3 SLIDESHOW GALLERY by Roko C. buljan
http://stackoverflow.com/a/34696029/383904
*/

.CSSgal {
	position: relative;
	overflow: hidden;
	height: 100%; /* Or set a fixed height */
}

/* SLIDER */

.CSSgal .slider {
	height: 100%;
	white-space: nowrap;
	font-size: 0;
	transition: 0.8s;
}

/* SLIDES */

.CSSgal .slider > * {
	font-size: 1rem;
	display: inline-block;
	white-space: normal;
	vertical-align: top;
	height: 100%;
	width: 100%;
	background: none 50% no-repeat;
	background-size: cover;
}

.slider img {
    max-height: 500px;
}

/* PREV/NEXT, CONTAINERS & ANCHORS */

.CSSgal .prevNext {
	position: absolute;
	z-index: 1;
	top: 50%;
	width: 100%;
	height: 0;
}

.CSSgal .prevNext > div+div {
	visibility: hidden; /* Hide all but first P/N container */
}

.CSSgal .prevNext a {
	background: #fff;
	position: absolute;
	width:       60px;
	height:      60px;
	line-height: 60px; /* If you want to place numbers */
	text-align: center;
	opacity: 0.7;
	-webkit-transition: 0.3s;
					transition: 0.3s;
	-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
	left: 0;
}
.CSSgal .prevNext a:hover {
	opacity: 1;
}
.CSSgal .prevNext a+a {
	left: auto;
	right: 0;
}

/* NAVIGATION */

.CSSgal .bullets {
	position: absolute;
	z-index: 2;
	bottom: 0;
	padding: 10px 0;
	width: 100%;
	text-align: center;
}
.CSSgal .bullets > a {
	display: inline-block;
	width:       30px;
	height:      30px;
	line-height: 30px;
	text-decoration: none;
	text-align: center;
	background: rgba(255, 255, 255, 1);
	-webkit-transition: 0.3s;
					transition: 0.3s;
}
.CSSgal .bullets > a+a {
	background: rgba(255, 255, 255, 0.5); /* Dim all but first */
}
.CSSgal .bullets > a:hover {
	background: rgba(255, 255, 255, 0.7) !important;
}

/* NAVIGATION BUTTONS */
/* ALL: */
.CSSgal >s:target ~ .bullets >* {      background: rgba(255, 255, 255, 0.5);}
/* ACTIVE */
#s1:target ~ .bullets >*:nth-child(1) {background: rgba(255, 255, 255,   1);}
#s2:target ~ .bullets >*:nth-child(2) {background: rgba(255, 255, 255,   1);}
#s3:target ~ .bullets >*:nth-child(3) {background: rgba(255, 255, 255,   1);}
#s4:target ~ .bullets >*:nth-child(4) {background: rgba(255, 255, 255,   1);}
/* More slides? Add here more rules */

/* PREV/NEXT CONTAINERS VISIBILITY */
/* ALL: */
.CSSgal >s:target ~ .prevNext >* {      visibility: hidden;}
/* ACTIVE: */
#s1:target ~ .prevNext >*:nth-child(1) {visibility: visible;}
#s2:target ~ .prevNext >*:nth-child(2) {visibility: visible;}
#s3:target ~ .prevNext >*:nth-child(3) {visibility: visible;}
#s4:target ~ .prevNext >*:nth-child(4) {visibility: visible;}
/* More slides? Add here more rules */

/* SLIDER ANIMATION POSITIONS */

#s1:target ~ .slider {transform: translateX(   0%); -webkit-transform: translateX(   0%);}
#s2:target ~ .slider {transform: translateX(-100%); -webkit-transform: translateX(-100%);}
#s3:target ~ .slider {transform: translateX(-200%); -webkit-transform: translateX(-200%);}
#s4:target ~ .slider {transform: translateX(-300%); -webkit-transform: translateX(-300%);}
/* More slides? Add here more rules */


/* YOU'RE THE DESIGNER!
   ____________________
   All above was mainly to get it working :)
   CSSgal CUSTOM STYLES / OVERRIDES HERE: */

.CSSgal{
	color: #fff;
	text-align: center;
}
.CSSgal .slider h2 {
	margin-top: 40vh;
	font-weight: 200;
	letter-spacing: -0.06em;
	word-spacing: 0.2em;
	font-size: 3em;
}
.CSSgal a {
	border-radius: 50%;
	margin: 0 3px;
	color: rgba(0,0,0,0.8);
	text-decoration: none;
}

/* Mobile Layout */
@media only screen and (max-width: 759px) {

  #warningtext{
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
  }
  
  .messageupload {
    margin-top: 2em;
  }

  .logo {
    width: 80% !important;
    height: 80px !important;
    top: 5px;
    left: 0px;
    position: fixed;
    font-size: 20px;
    z-index: 3;
  }

  .logo img {
    max-width: 100px;
  }

  .homelink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .homelink:hover {
    background-color: #b94db3;
    color: #fff !important;
  }

  .contributelink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .contributelink:hover {
    background-color: #cc3d46;
    color: #fff !important;
    text-decoration: underline wavy #cc3d46;
  }

  .aboutlink {
    background-color: #fff;
    margin: 0.1em !important;
    color: #000 !important;
  }

  .aboutlink:hover {
    background-color: #2452c2;
    color: #fff !important;
    text-decoration: underline wavy #2452c2;
  }

  .lbu {
    top: 15px !important;
    right: 0.2em !important;
    position: fixed !important;
    z-index: 5;

    padding: .01em !important ;
    padding-left: .01em !important;
    padding-right: .01em !important;
    margin: 0px !important;
    font-weight: bold !important;
    font-size: 12px;
  }

  .contribute {
    padding-top: 2em;
  }

  .titleresize-connect {
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    /* text-transform: uppercase; */
    color: #2452c2;
    line-height: 1.1;
    /* color: black; */

  }

  .titleresize-found-one {
    text-align: center;
    font-weight: bold;
    font-size: 45px;
    line-height: 1.2;
  }

  .subtitleresize-center {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.4;
  }

  .subtitleresize-center-code {
    padding-top: 2em;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 0.0;
    padding-bottom: 0.5em;
  }

  .subtitleresize-center-textfield {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 1.0;
  }

  .textresize-center {
    text-align: center;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.4;
  }

  .image-upload {
    text-align: center;
    min-height: 800px !important;
    border: none;
    font-size: 17px;
    /* padding: 2em; */
    /* outline-offset: -1em; */
    /* background-color: #FFF; */
    color: #000;
    /* outline: 2px dashed #2452c2; */
    align-items: center !important;
    justify-content: center;
    display: flex;
    background-image: url('../assets/photos/placeholder_photo_circle_red.png') !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .uploadbutton {
    padding: 1em 1em 1em 1em;
    background-color: #fff !important;
    color: #2452c2 !important;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    /* border-radius: 10px; */
    border: #2452c2 1px solid !important;
    /* border: 4px solid #ff6666; */
  }

  .image-upload:hover {
    /* background-color: #e6ebf7; */
    /* outline: 2px dashed #757575 !important; */
  }

  .messageField {
    margin-top: 2em;
    border: #2452c2 1px solid !important;
    font-size: 17px;
    /* padding: 2em; */
    background-color: #FFF;
    /* outline: 2px solid #2452c2 !important; */
    min-height: 100px;
    /* font-size: 17px; */
    color: #2452c2 !important;
    /* outline-offset: 0.9em; */
    /* border-radius: 10px; */
  }

  .messageField:hover {
    background-color: #FFF;
    /* outline: 2px dashed #757575 !important; */
  }

  .messageField:focus {
    background-color: #FFF;
    /* outline: 2px dashed #757575 !important; */
    outline-offset: 0.9em;
  }

  .codeinputfield {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .codefield {
    color: #2452c2;
    border-radius: 0px;
    border: #2452c2 1px solid !important;
    /* margin-right: 2em; */
  }

  .buttonsCode {
    display: flex;
    justify-content: center;
  }

  .icon {
    background: #fff;
    color: #2452c2;
    margin: 3px;
    /* padding-left: 30px;
    padding-right: 30px; */
    line-height: 30px;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    width: 1.5em;
    height: 1.5em;
  }

  .icon:hover {
    /* outline: 4px #ff6666 solid;
    outline-offset: -4px; */
    /* background-color: #ff6666; */
    color: #ff6666;
  }


  #code {
    padding: 6px;
    height: 45px;
    line-height: 25px;
    font-size: 15px;
    margin-right: 0.3em;
    font-family: "Ionicons", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    letter-spacing: 3px;
  }

  .warning{
    color: #7e7e7e;
    margin-top: 2em;
    font-size: 15px;
  }

  .uploadbuttonmiddle {
    display: flex;
    align-items: center !important;
    justify-content: center;
  }

  .finaluploadbutton {
    margin-top: 1em !important;
    background-color: #ffffff !important;
    font-size: 30px !important;
    font-weight: bold !important;
    cursor: pointer;
    color: #2452c2 !important;
    /* border-radius: 10px; */
    border: #2452c2 1px solid !important;
  }

  .finaluploadbutton:hover {
    background-color: #2452c2 !important;
    font-size: 30px !important;
    font-weight: bold !important;
    color: #ffffff !important;
    cursor: pointer;
  }

  .counter {
    position: absolute;
    bottom: 5.2em;
    right: 0.5em !important;
    font-weight: bold;
    /* display: flex; */
    text-align: right;
    line-height: 1.0;
    font-size: 15px;
    /* background-color: #ccf0fd; */
    padding: 1em !important;
    opacity: 0.8;
  }

  .progress-meter {
    background-color: #000;
  }

  .progress {
    background-color: #dfdfdf;
  }

  #uploadBar {
    display: none;
  }

  #afterupload {
    display: none;
  }

  #MissingFile, #MissingCode, #InvalidFileParameter, #InvalidFileType, #InvalidFileSize, #InvalidCodeFormat, #GeolocationUnsupported, #GeolocationDenied, #GeolocationUnavailable, #GeolocationTimeout, #InvalidCode, #UploadSize, #UploadError {
    display: none;
    font-size: 20px;
    color: #ff6666;
    text-align: center;
  }

  .about {
    background-image: linear-gradient(rgba(244, 244, 244, 0.97) , rgba(244, 244, 244, 0.8));
    color: #2452c2;
    z-index: 1;
  }

  .titleresize-about {
    margin-top: 7em;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    /* text-transform: uppercase; */
    /* color: #2452c2; */
    line-height: 1.2;
    /* text-decoration: underline wavy #2452c2; */
    text-underline-offset: 5px;
  }

  .textresize {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.4;
  }

  .textresize-center a {
    color: #2452c2 !important;
    font-weight: bold !important;
    text-decoration: underline !important;
  }

  .credits {
    color: #2452c2;
    background-color: rgba(244, 244, 244, 0.8);
    padding-top: 2em;
    padding-bottom: 2em;
    text-align: center;
  }
  
  .instagramlink {
    height:34px;
  }
  .instagramlink i {
    font-size: 20px;
  }
  
  .patreonlink {
    height:34px;
    width:34px;
  }

  .patreonlink img {
    padding-top:  0.7rem !important;
    height: 24px;
    /* min-width: 10px !important; */
    /* width: 28px !important; */
    line-height: 1.1;
  }
  
  .menu a {
    padding-left: 7px !important;
    padding-right: 7px !important;
  }
}
